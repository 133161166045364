import { graphql } from 'gatsby';

import React, { Component } from 'react';
import { get, find } from 'lodash';
import styled, { withTheme } from 'styled-components';
import { Row, Col } from 'antd';

import Layout from '../layout/Layout';

import { SectionTitle, PageHero, Link, Paragraph } from '../common/components';

interface Props {
  data: any;
  location: any;
}

const GoBackText = styled(Paragraph)``;

const ContentRow = styled(Row)`
  overflow: hidden !important;
  text-align: center;
  a {
    text-decoration: underline;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  ul,
  li,
  p,
  a {
    color: ${props => props.theme.color.text.main} !important;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5,
  h6 {
    font-size: 18px;
  }
  p,
  ol,
  ul,
  li {
    font-size: 16px;
  }
  img {
    max-width: 100% !important;
  }

  margin-bottom: 5%;
`;

class Article extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const content = get(data, 'page.data.content.html', 'No content');
    const articleTitle = get(data, 'page.data.title', null);
    const body = get(data, 'page.data.body');
    const heroData = find(body, { __typename: 'PrismicArticleBodyHero' });
    const articleGoBackText = get(data, 'page.data.go_back_text', null);
    const articleGoBackUrl = get(data, 'page.data.go_back_url.url', null);
    // const articleTitle = get(data, 'page.data.title', null);
    // console.log('data', data, articleGoBackText, articleGoBackUrl);

    return (
      <Layout data={data} location={this.props.location}>
        {heroData && <PageHero data={heroData} />}
        {articleTitle && <SectionTitle title={articleTitle} />}
        <ContentRow>
          <Col xs={20} offset={2}>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Col>
          {articleGoBackText && articleGoBackUrl && (
            <Col xs={20} offset={2}>
              <Link href={articleGoBackUrl}>
                <GoBackText>{articleGoBackText}</GoBackText>
              </Link>
            </Col>
          )}
        </ContentRow>
      </Layout>
    );
  }
}

export default withTheme(Article);

export const query = graphql`
  query ArticleQuery($slug: String!) {
    page: prismicArticle(id: { eq: $slug }) {
      ...Article
    }
  }
`;
